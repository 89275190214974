import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import classnames from 'classnames';
import Button from 'reactstrap/lib/Button';
import { ShareIcon } from 'site-modules/shared/components/social-media/share-icon/share-icon';
import { SOCIAL_TRACKING_VALUE } from 'client/constants/social-media';

import './social-share-icons.scss';

export function SocialShareIcons({
  url,
  title,
  socialIcons,
  socialTrackingId,
  className,
  onShareLinkIconClick,
  onShareCodeIconClick,
  hasShareCodeIcon,
  hasShareLinkIcon,
}) {
  return (
    <Fragment>
      {socialIcons.map(brand => (
        <ShareIcon
          key={brand}
          brand={brand}
          title={title}
          url={url}
          className={classnames('social-share-icon py-0 px-0_25', className)}
          data-tracking-id={socialTrackingId}
          data-tracking-value={socialTrackingId ? SOCIAL_TRACKING_VALUE[brand] : undefined}
        />
      ))}
      {hasShareLinkIcon && (
        <Button className="share-btn py-0 px-0_25" color="link" onClick={onShareLinkIconClick}>
          <i className={classnames('icon-share3', className)} />
        </Button>
      )}
      {hasShareCodeIcon && (
        <Button className="share-btn py-0 px-0_25" color="link" onClick={onShareCodeIconClick}>
          <i className={classnames('icon-embed', className)} />
        </Button>
      )}
    </Fragment>
  );
}

SocialShareIcons.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  socialIcons: PropTypes.arrayOf(PropTypes.string),
  socialTrackingId: PropTypes.string,
  onShareLinkIconClick: PropTypes.func,
  onShareCodeIconClick: PropTypes.func,
  hasShareCodeIcon: PropTypes.bool,
  hasShareLinkIcon: PropTypes.bool,
  className: PropTypes.string,
};

SocialShareIcons.defaultProps = {
  url: '',
  title: '',
  socialIcons: ['facebook', 'twitter', 'linkedin', 'reddit'],
  socialTrackingId: null,
  onShareLinkIconClick: noop,
  onShareCodeIconClick: noop,
  hasShareCodeIcon: false,
  hasShareLinkIcon: false,
  className: 'size-20',
};
