import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import Modal from 'reactstrap/lib/Modal';
import Button from 'reactstrap/lib/Button';
import { SocialShareIcons } from 'client/site-modules/shared/components/social-share-icons/social-share-icons';
import { ShareWidget } from 'client/site-modules/shared/components/share-widget/share-widget';
import { CopyToClipboardInput } from 'site-modules/shared/components/copy-to-clipboard-input/copy-to-clipboard-input';

import './social-share-dialog.scss';

export function SocialShareDialog({
  isOpen,
  toggle,
  headingText,
  pageTitle,
  pageUrl,
  className,
  socialIcons,
  socialTrackingId,
  shareableWidgetData,
  hideSocialIcons,
}) {
  const hasShareableWidgetData = !isEmpty(shareableWidgetData);
  const [isShareWidgetOpened, setShareWidgetOpened] = useState(hasShareableWidgetData);

  const openShareLinkMenu = useCallback(() => {
    setShareWidgetOpened(false);
  }, []);

  const openShareWidgetMenu = useCallback(() => {
    setShareWidgetOpened(true);
  }, []);

  return isOpen ? (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size="md"
      contentClassName={classnames('social-share-dialog border-0 rounded-12 px-1 pt-1 pb-1_5', className)}
    >
      <h5 className="modal-heading heading-5 mb-1 pb-1">{headingText}</h5>
      <Button color="link" onClick={toggle} className="pos-a top-0 right-0 mt-0_5 mr-0_5 px-0_5 py-0_5">
        <i className="icon-cross2 size-16 text-gray-darker" aria-hidden />
      </Button>
      {isShareWidgetOpened ? (
        <ShareWidget {...shareableWidgetData} backLink={pageUrl} />
      ) : (
        <CopyToClipboardInput
          btnColor="blue-50"
          btnClassName="px-1 px-md-1_5 text-transform-none font-weight-normal font-weight-medium"
          inputValue={pageUrl}
          label="Copy link"
        />
      )}
      {!hideSocialIcons && (
        <div className="d-flex justify-content-between align-items-center mt-2">
          <SocialShareIcons
            socialIcons={socialIcons}
            socialTrackingId={socialTrackingId}
            className="text-blue-30 size-20 px-0_5"
            title={pageTitle}
            url={pageUrl}
            onShareLinkIconClick={openShareLinkMenu}
            onShareCodeIconClick={openShareWidgetMenu}
            hasShareCodeIcon={hasShareableWidgetData}
            hasShareLinkIcon={hasShareableWidgetData}
          />
        </div>
      )}
    </Modal>
  ) : null;
}

SocialShareDialog.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  pageTitle: PropTypes.string,
  pageUrl: PropTypes.string,
  className: PropTypes.string,
  headingText: PropTypes.string,
  socialIcons: PropTypes.arrayOf(PropTypes.string),
  socialTrackingId: PropTypes.string,
  shareableWidgetData: PropTypes.shape({
    widgetName: PropTypes.string,
    imageSrc: PropTypes.string,
    defaultWidth: PropTypes.number,
    defaultHeight: PropTypes.number,
    minWidth: PropTypes.number,
    minHeight: PropTypes.number,
    defaultTitle: PropTypes.string,
    isTitleEditable: PropTypes.bool,
    queryParams: PropTypes.shape({}),
  }),
  hideSocialIcons: PropTypes.bool,
};

SocialShareDialog.defaultProps = {
  isOpen: false,
  pageTitle: '',
  pageUrl: '',
  className: null,
  headingText: 'Share options',
  socialIcons: undefined,
  socialTrackingId: undefined,
  shareableWidgetData: {},
  hideSocialIcons: false,
};
