import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { StickyIcon } from 'site-modules/shared/components/sticky-icon/sticky-icon';

export function CtaButtonText({ text, hideOpenIcon }) {
  return (
    <Fragment>
      {text}
      {!hideOpenIcon && <StickyIcon className="icon-open_in_new small" style={{ height: '12px', width: '12px' }} />}
    </Fragment>
  );
}

CtaButtonText.propTypes = {
  text: PropTypes.string.isRequired,
  hideOpenIcon: PropTypes.bool,
};

CtaButtonText.defaultProps = {
  hideOpenIcon: false,
};
