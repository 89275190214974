import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import InputGroup from 'reactstrap/lib/InputGroup';
import InputGroupAddon from 'reactstrap/lib/InputGroupAddon';
import Input from 'reactstrap/lib/Input';
import Button from 'reactstrap/lib/Button';
import Tooltip from 'reactstrap/lib/Tooltip'; // eslint-disable-line no-restricted-imports

import './copy-to-clipboard-input.scss';

export const CopyToClipboardInput = ({
  inputValue,
  className,
  btnClassName,
  btnColor,
  trackingId,
  label,
  sharedLinkRef,
  trackingValue,
  autohide,
}) => {
  const [state, setState] = useState({
    isTooltipOpen: false,
    copiedSuccessfully: false,
  });

  const copyToClipboard = async () => {
    try {
      // Currently supported only by Chrome and Firefox
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(inputValue);
      } else {
        const input = document.getElementById('copy-to-clipboard-input');
        const range = document.createRange();
        range.selectNodeContents(input);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        input.setSelectionRange(0, 999999);
        document.execCommand('copy');
        selection.removeAllRanges();
      }

      setState({ isTooltipOpen: true, copiedSuccessfully: true });
    } catch {
      setState({ isTooltipOpen: true, copiedSuccessfully: false });
    } finally {
      if (autohide) {
        setTimeout(() => {
          setState({ isTooltipOpen: false });
        }, 2000);
      }
    }
  };

  return (
    <InputGroup className={classnames('copy-to-clipboard-input', className)}>
      <InputGroupAddon addonType="prepend">
        <Button
          color={btnColor}
          onClick={copyToClipboard}
          id="share-link-btn"
          className={classnames('share-link-btn rounded-8', btnClassName)}
          data-tracking-id={trackingId}
          data-tracking-value={trackingValue}
        >
          {label}
        </Button>
      </InputGroupAddon>
      <Input
        id="copy-to-clipboard-input"
        key={inputValue}
        defaultValue={inputValue}
        className="text-gray-darker"
        innerRef={sharedLinkRef}
      />
      <Tooltip placement="top" isOpen={state.isTooltipOpen} target="share-link-btn">
        {state.copiedSuccessfully ? 'Copied!' : 'Please copy manually'}
      </Tooltip>
    </InputGroup>
  );
};

CopyToClipboardInput.propTypes = {
  inputValue: PropTypes.string,
  className: PropTypes.string,
  btnClassName: PropTypes.string,
  btnColor: PropTypes.string,
  trackingId: PropTypes.string,
  trackingValue: PropTypes.string,
  label: PropTypes.string,
  sharedLinkRef: PropTypes.shape(),
  autohide: PropTypes.bool,
};

CopyToClipboardInput.defaultProps = {
  inputValue: '',
  className: null,
  btnClassName: 'px-1 text-transform-none',
  btnColor: 'primary-b',
  trackingId: undefined,
  trackingValue: undefined,
  label: 'COPY',
  sharedLinkRef: null,
  autohide: false,
};
