import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import Tooltip from 'reactstrap/lib/Tooltip'; // eslint-disable-line no-restricted-imports
import FormGroup from 'reactstrap/lib/FormGroup';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { objectToQueryString } from 'site-modules/shared/utils/string';
import { Checkbox } from 'site-modules/shared/components/checkbox/checkbox';

import './share-widget.scss';

export class ShareWidget extends Component {
  static propTypes = {
    widgetName: PropTypes.string,
    imageSrc: PropTypes.string,
    defaultWidth: PropTypes.number,
    defaultHeight: PropTypes.number,
    minWidth: PropTypes.number,
    minHeight: PropTypes.number,
    defaultTitle: PropTypes.string,
    queryParams: PropTypes.shape({}),
    trackingCreativeId: PropTypes.string,
    hasCopyTracking: PropTypes.bool,
    isTitleEditable: PropTypes.bool,
    backLink: PropTypes.string.isRequired,
  };

  static defaultProps = {
    widgetName: '',
    imageSrc: null,
    defaultWidth: 290,
    minWidth: 1,
    defaultHeight: 600,
    minHeight: 1,
    defaultTitle: '',
    queryParams: {},
    trackingCreativeId: null,
    hasCopyTracking: false,
    isTitleEditable: true,
  };

  state = {
    width: this.props.defaultWidth,
    height: this.props.defaultHeight,
    title: this.props.defaultTitle,
    isTooltipOpen: false,
    copiedSuccessfully: false,
    preserveAspectRatio: true,
  };

  onWidthInputBlur = () => {
    const { defaultHeight, defaultWidth, minWidth } = this.props;
    const { width, height, preserveAspectRatio } = this.state;

    const newWidth = width < minWidth ? minWidth : width;
    let adjustedHeight = height;

    if (preserveAspectRatio) {
      adjustedHeight = Math.round((newWidth * defaultHeight) / defaultWidth);
    }

    this.setState({
      width: newWidth,
      height: adjustedHeight,
    });
  };

  onHeightInputBlur = () => {
    const { defaultHeight, defaultWidth, minHeight } = this.props;
    const { height, width, preserveAspectRatio } = this.state;

    const newHeight = height < minHeight ? minHeight : height;
    let adjustedWidth = width;

    if (preserveAspectRatio) {
      adjustedWidth = Math.round((newHeight * defaultWidth) / defaultHeight);
    }

    this.setState({
      width: adjustedWidth,
      height: newHeight,
    });
  };

  setWidth = e => {
    this.setState({ width: e.target.value });
  };

  setHeight = e => {
    this.setState({ height: e.target.value });
  };

  setTitle = e => {
    this.setState({ title: e.target.value });
  };

  setPreserveAspectRatio = e => {
    this.setState({ preserveAspectRatio: e.target.checked });
  };

  handleInputClick = async e => {
    const { hasCopyTracking, trackingCreativeId } = this.props;

    if (hasCopyTracking) {
      EventToolbox.fireTrackAction({
        event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
        event_data: {
          action_category: TrackingConstant.USER_ACTION_CATEGORY,
          action_name: TrackingConstant.ACTION_EMBED_CONTENT,
          subaction_name: 'copy_embed_code',
          action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
          creative_id: trackingCreativeId,
        },
      });
    }

    try {
      await navigator.clipboard.writeText(e.target.value);
      this.setState({ isTooltipOpen: true, copiedSuccessfully: true });
    } catch {
      this.setState({ isTooltipOpen: true, copiedSuccessfully: false });
    }
  };

  render() {
    const {
      widgetName,
      imageSrc,
      minWidth,
      minHeight,
      defaultTitle,
      isTitleEditable,
      queryParams,
      backLink,
    } = this.props;

    if (!widgetName && !imageSrc) return null;

    const { title, width, height, isTooltipOpen, copiedSuccessfully, preserveAspectRatio } = this.state;

    const query = objectToQueryString({
      ...queryParams,
      title: title && title !== defaultTitle ? title : null,
    });

    const contentPart = widgetName
      ? `<iframe src="https://www.edmunds.com/widgets/${widgetName}/${
          query ? `?${query}` : ''
        }" width="${width}" height="${height}" style="border: none; overflow: hidden; max-width: 100%" scrolling="yes" frameborder="0" allowfullscreen="true" title="${title}"></iframe>`
      : `<img src="${imageSrc}" width="${width}" height="${height}" style="object-fit: contain; max-width: 100%" alt="" />`;

    const hostedSiteEmbedCode = `${contentPart} <br /> <a href="${backLink}" target="_blank" rel="noopener noreferrer">${title ||
      defaultTitle}</a>`;

    return (
      <div className="share-widget">
        <FormGroup>
          <Label for="hostedSiteEmbedCode">Click the code to copy:</Label>
          <Input
            type="textarea"
            className="rounded-8"
            name="hostedSiteEmbedCode"
            id="hostedSiteEmbedCode"
            value={hostedSiteEmbedCode}
            onClick={this.handleInputClick}
            readOnly
            rows={7}
            data-testid="code-input"
          />
          <Tooltip placement="top" isOpen={isTooltipOpen} target="hostedSiteEmbedCode" data-testid="tooltip">
            {copiedSuccessfully ? 'Copied!' : 'Please copy manually'}
          </Tooltip>
        </FormGroup>
        <FormGroup className="d-flex align-items-center">
          <span className="text-nowrap mr-1">Custom size</span>
          <Input
            className="rounded-8"
            aria-label="custom width"
            value={width}
            min={minWidth}
            type="number"
            onChange={this.setWidth}
            onBlur={this.onWidthInputBlur}
            data-testid="width-input"
          />
          <span className="mx-1" aria-hidden>
            x
          </span>
          <Input
            aria-label="custom height"
            className="rounded-8"
            value={height}
            min={minHeight}
            type="number"
            onChange={this.setHeight}
            onBlur={this.onHeightInputBlur}
            data-testid="height-input"
          />
        </FormGroup>
        <FormGroup className="checkbox-wrapper">
          <Checkbox
            id="preserve-aspect-ratio"
            data-testid="reserve-aspect-ratio-checkbox"
            containerClassName="my-0_75"
            checked={preserveAspectRatio}
            onChange={this.setPreserveAspectRatio}
            labelText="Preserve aspect ratio"
            textLabelClassName="ml-2"
          />
        </FormGroup>
        {isTitleEditable && (
          <FormGroup className="d-flex align-items-center">
            <span className="text-nowrap mr-1">Custom title</span>
            <Input
              className="rounded-8"
              aria-label="custom title"
              value={title}
              type="text"
              data-testid="title-input"
              onChange={this.setTitle}
            />
          </FormGroup>
        )}
      </div>
    );
  }
}
