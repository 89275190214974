const SOCIAL_TO_ICON_MAPPING = {
  facebook: 'facebook2',
  twitter: 'x',
  x: 'x',
  linkedin: 'linkedin',
  email: 'envelop',
  instagram: 'instagram',
  youtube: 'play',
  google_plus: 'google',
  tiktok: 'tiktok',
  reddit: 'reddit',
  rss: 'rss',
};

const SOCIAL_TO_LABEL_MAPPING = {
  facebook: 'Facebook',
  twitter: 'X',
  x: 'X',
  instagram: 'Instagram',
  youtube: 'Youtube',
  google_plus: 'Google Plus',
  tiktok: 'Tiktok',
  rss: 'RSS Feed',
};

const SOCIAL_SHARE_TEMPLATES = {
  facebook: ({ url, title }) => `https://www.facebook.com/share.php?u=${url}&title=${title}`,
  twitter: ({ url, title }) =>
    `https://www.twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(title)}`,
  x: ({ url, title }) =>
    `https://www.twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(title)}`,
  linkedin: ({ url, title }) => `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}`,
  email: ({ url, title }) => `mailto:?to=&body=Read on Edmunds.com: ${url}&subject=FYI: ${title} from Edmunds.com`,
  reddit: ({ url, title }) => `https://www.reddit.com/submit?url=${url}&title=${title}`,
  rss: ({ url }) => url,
  // Add more, pinterest, etc
};

export const SOCIAL_TRACKING_VALUE = {
  facebook: 'Facebook',
  x: 'X',
  linkedin: 'LinkedIn',
  reddit: 'Reddit',
  email: 'Email',
};

export const getTemplateForSocialShare = brand => SOCIAL_SHARE_TEMPLATES[brand];

export const getIconClassForSocialBrand = brand => SOCIAL_TO_ICON_MAPPING[brand];

export const getLabelForSocialBrand = brand => SOCIAL_TO_LABEL_MAPPING[brand];

export const SOCIAL_TO_ICON_BRANDS = Object.keys(SOCIAL_TO_ICON_MAPPING);
export const SOCIAL_TO_LABEL_BRANDS = Object.keys(SOCIAL_TO_LABEL_MAPPING);
export const SOCIAL_SHARE_BRANDS = Object.keys(SOCIAL_SHARE_TEMPLATES);
