import { useCallback, useState } from 'react';
import { compareValues } from 'site-modules/shared/components/editorial/article-table/article-table-helpers';

export const SORT_ORDER = {
  NONE: 'none',
  ASCENDING: 'ascending',
  DESCENDING: 'descending',
};

export const useTableSort = ({ initialSortColumn, initialSortOrder, rowValues, setRowValues }) => {
  const [sortColumn, setSortColumn] = useState(initialSortColumn);
  const [sortOrder, setSortOrder] = useState(initialSortOrder);

  const onColumnSort = useCallback(
    event => {
      const columnKey = parseInt(event.currentTarget.dataset.key, 10) || event.currentTarget.dataset.key;

      if (columnKey === sortColumn) {
        setSortOrder(sortOrder === SORT_ORDER.ASCENDING ? SORT_ORDER.DESCENDING : SORT_ORDER.ASCENDING);
        setRowValues(rowValues.reverse());

        return;
      }

      setSortColumn(columnKey);
      setSortOrder(SORT_ORDER.ASCENDING);
      setRowValues(rowValues.sort((a, b) => compareValues(a[columnKey], b[columnKey])));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rowValues, sortColumn, sortOrder]
  );

  return [sortColumn, sortOrder, onColumnSort];
};
