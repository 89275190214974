import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getTemplateForSocialShare, getIconClassForSocialBrand } from 'client/constants/social-media';

export function ShareIcon({ brand, title, url, className, ...restProps }) {
  const template = getTemplateForSocialShare(brand);
  const iconClass = getIconClassForSocialBrand(brand);
  const correctUrl = /(https|http)/.test(url) ? url : ['https:', url].join('');
  return (
    <a
      href={template({ url: correctUrl, title })}
      className={classnames('btn btn-link btn-lg text-decoration-none', className)}
      rel="noopener noreferrer"
      target="_blank"
      aria-label={brand}
      {...restProps}
    >
      <span className={`icon-${iconClass} align-text-top`} />
    </a>
  );
}

ShareIcon.propTypes = {
  brand: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
};

ShareIcon.defaultProps = {
  className: null,
};
